import React, { useState, useEffect } from "react"
import { Div } from "atomize"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useWindowSize } from "react-use"

// import GifPlayer from "react-gif-player"

String.prototype.imgURL = function (size) {
  // remove any current image size then add the new image size
  return this.replace(
    /_(pico|icon|thumb|small|compact|medium|large|grande|original|1024x1024|2048x2048|master)+\./g,
    "."
  ).replace(/\.jpg|\.png|\.gif|\.jpeg/g, function (match) {
    return "_" + size + match
  })
}

// alternatively, if you don't want to use a polyfil
function imgURL(src, size) {
  if (src && size) {
    return src
      .replace(
        /_(pico|icon|thumb|small|compact|medium|large|grande|original|1024x1024|2048x2048|master)+\./g,
        "."
      )
      .replace(/\.jpg|\.png|\.gif|\.jpeg/g, function (match) {
        return "_" + size + match
      })
  }
  // remove any current image size then add the new image size

  return src
}

const ImageLazyLoad = props => {
  const [loaded, setLoaded] = useState(false)
  const [isContentful, setIsContentful] = useState(
    props.isContentful ? props.isContentful : false
  )
  const { width: windowWidth } = useWindowSize()

  const {
    children,
    bgImg,
    q,
    size,
    pausable,
    isSelected,
    id,
    mobRatio,
    ...rest
  } = props

  const afterImageLoad = () => {
    setLoaded(true)
  }

  // useEffect(() => {
  //   const gifPlayer = document.querySelector("img.gif-player.still")
  //     ?.parentElement
  //   if (gifPlayer) {
  //     gifPlayer.style.pointerEvents = "none"
  //   }
  // }, [isSelected])

  // bgImg = `${bgImg}?q=70`;

  useEffect(() => {
    if (mobRatio && loaded && windowWidth <= 576) {
      document.querySelector(".lazy-load-mobile").style.height = `${
        document.querySelector(".lazy-img-container")?.clientWidth - 40
      }px`
    }
  }, [loaded])

  if (isContentful) {
    return (
      <Div
        pos="relative"
        overflow="hidden"
        style={
          loaded
            ? {
                backgroundImage: `url(${bgImg}${q ? `?${q}` : ""})`,
                backgroundColor: "transparent",
              }
            : { backgroundColor: "#f6f6f6" }
        }
        {...rest}
      >
        <Div
          pos="absolute"
          top="0"
          left="0"
          bottom="0"
          right="0"
          opacity={loaded ? "0" : "1"}
          transition="opacity 0.5s ease-in-out"
          style={{
            background: `url(${bgImg}?q=20&w=200) center center/cover`,
            filter: "blur(34px)",
          }}
        />
        {!loaded && (
          <LazyLoadImage
            height={"100%"}
            afterLoad={afterImageLoad}
            src={bgImg}
            width={"100%"}
            style={{ opacity: "0", position: "absolute", zIndex: "-100000" }}
            threshold={0}
          />
        )}
        {children}
      </Div>
    )
  }

  // if (bgImg?.endsWith(".gif") && pausable) {
  //   return !isSelected ? (
  //     <>
  //       <img
  //         onClick={e => e.target.nextSibling.click()}
  //         className="gif-play-icon"
  //         src="/Frame.png"
  //         alt=""
  //       />
  //       <GifPlayer gif={bgImg} autoplay={false} className="gif-player" />
  //     </>
  //   ) : (
  //     <GifPlayer gif={bgImg} autoplay={false} className="gif-player still" />
  //   )
  // }

  // if (width <= 992) {
  //   return <img src={bgImg} height="100%" width="100%" />
  // }

  return (
    <Div
      pos="relative"
      overflow="hidden"
      id={id}
      style={
        loaded
          ? {
              backgroundImage: `url(${bgImg})`,
              backgroundColor: "transparent",
            }
          : { backgroundColor: "#f6f6f6" }
      }
      // h={{
      //   xs:
      //     mobRatio &&
      //     `${
      //       document.querySelector(".lazy-img-container")?.clientWidth - 40
      //     }px`,
      //   sm: mobRatio && "auto",
      // }}
      {...rest}
      className={mobRatio ? "lazy-load-mobile" : ""}
    >
      <Div
        pos="absolute"
        top="0"
        left="0"
        bottom="0"
        right="0"
        opacity={loaded ? "0" : "1"}
        transition="opacity 0.5s ease-in-out"
        style={{
          background: `url(${imgURL(bgImg, size)}) center center/cover`,
          filter: "blur(34px)",
        }}
      />
      {!loaded && (
        <LazyLoadImage
          height={"100%"}
          afterLoad={afterImageLoad}
          src={imgURL(bgImg, size)}
          width={"100%"}
          style={{ opacity: "0", position: "absolute" }}
        />
      )}
      {children}
    </Div>
  )
}

export default ImageLazyLoad
